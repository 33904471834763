import React from 'react';
import LoginComponent from '../components/signup/LoginComponent';
import SignupComponent from '../components/signup/SignupComponent';
import { Routes, Route } from 'react-router-dom';
const AuthContainer = () => {
  return (
    <div className="auth-container flex flex-wrap justify-between items-start">
      <div className="logo-wrapper w-full">
        <div className="logo-container"></div>
      </div>
      <div className="form-container w-full">
        <Routes>
          <Route path="login" element={<LoginComponent />} />
          <Route path="signup" element={<SignupComponent />} />
        </Routes>
      </div>
    </div>
  );
};

export default AuthContainer;
