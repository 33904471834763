import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

function TeamCompositions(props) {
    const { idehavere, startups, teammembers, t } = props;
 
    // Explore the team composition of the startups
    // We will consider both the idehaver and the team members here, making no distinction between the two
    let TwoOrMoreMembersInStartupComposition = { male: 0, female: 0, other: 0, mixed: 0, error: 0};
    let OnlyOneMemberInStartup = {male: 0, female: 0, other: 0};
    let v2TwoOrMoreMembersInStartupComposition = { male: 0, female: 0, other: 0, mixed: 0, error: 0};
    let v2OnlyOneMemberInStartup = {male: 0, female: 0, other: 0};
    //Stats before v2 are not as accurate as the ones after v2, so we will have a switch to show only the v2 stats
    const [onlyV2, setOnlyV2] = useState(false);

    //Cutoff date for v2:
    // Dates in startup are stored as: registreringsDato: 1727429597139
    // The date is in milliseconds since 1970
    // The cutoff date is 1. oct 2024, but will become 1.nov soon TODO
    const v2CutoffDate = 1727771025733;
    // Separate the startups into categories - with only one member and with two or more members
    //Only look at the startups that are active
    let activeStartups = Object.values(startups).filter(startup => startup.status.value == 1);
    

    let activeStartupsWithOneMember = activeStartups.filter(startup => startup.teammedlemmer == null || Object.keys(startup.teammedlemmer).length == 0);
   
    let activeStartupsWithTwoOrMoreMembers = activeStartups.filter(startup => startup.teammedlemmer != null && Object.keys(startup.teammedlemmer).length > 0);
    

    // Only for active v2, set on the cutoff date
    let activeV2Startups = activeStartups.filter(startup => startup.registreringsDato > v2CutoffDate);
 
    // Use the same logic as above to separate the startups into categories
    let activeV2StartupsWithOneMember = activeV2Startups.filter(startup => startup.teammedlemmer == null || Object.keys(startup.teammedlemmer).length == 0);
    
    let activeV2StartupsWithTwoOrMoreMembers = activeV2Startups.filter(startup => startup.teammedlemmer != null && Object.keys(startup.teammedlemmer).length > 0);
    
    let listOfStartupsWithErrors = [];
    let v2ListOfStartupsWithErrors = [];

    // console.log("activeStartups");
    // console.log(activeStartups);
    // console.log("activeStartupsWithOneMember");
    // console.log(activeStartupsWithOneMember);
    // console.log("activeStartupsWithTwoOrMoreMembers");
    // console.log(activeStartupsWithTwoOrMoreMembers);
    // console.log("activeV2StartupsWithOneMember");
    // console.log(activeV2StartupsWithOneMember);
    // console.log("activeV2StartupsWithTwoOrMoreMembers");
    // console.log(activeV2StartupsWithTwoOrMoreMembers);
    // console.log("activeV2Startups");
    // console.log(activeV2Startups);


   // Count the gender composition of the startups with only one member
    activeStartupsWithOneMember.forEach(startup => {
        if (idehavere[startup.idehaver].sex == 'm') {
            OnlyOneMemberInStartup["male"] += 1;
        }
        else if (idehavere[startup.idehaver].sex == 'f') {
            OnlyOneMemberInStartup["female"] += 1;
        }
        else {
            OnlyOneMemberInStartup["other"] += 1;
        }
    });
    // console.log("OnlyOneMemberInStartup");
    // console.log(OnlyOneMemberInStartup);

    //For activeV2StartupsWithOneMember
    activeV2StartupsWithOneMember.forEach(startup => {
        if (idehavere[startup.idehaver].sex == 'm') {
            v2OnlyOneMemberInStartup["male"] += 1;
        }
        else if (idehavere[startup.idehaver].sex == 'f') {
            v2OnlyOneMemberInStartup["female"] += 1;
        }
        else {
            v2OnlyOneMemberInStartup["other"] += 1;
        }
    });

    // For startups with two or more members, iterate over the team members and check if teams are pure male, female, other or mixed
    activeStartupsWithTwoOrMoreMembers.forEach(startup => {
        let teamMembers = Object.values(startup.teammedlemmer).map(uid => teammembers && teammembers[uid]);
        let maleCount = 0;
        let femaleCount = 0;
        let otherCount = 0;
        let errorCount = 0;
        
        //First, check the idehaver
        
        if (idehavere[startup.idehaver].sex == 'm') {
            maleCount += 1;
        }
        else if (idehavere[startup.idehaver].sex == 'f') {
            femaleCount += 1;
        }
        else if (idehavere[startup.idehaver].sex == 'other') {
            otherCount += 1;
        }
        else {
            console.log("error on idehaver gender");
            console.log(idehavere[startup.idehaver]);
            errorCount += 1;
        }
        teamMembers.forEach(member => {
            
            if (member.sex == 'm') {
                maleCount += 1;
                
            }
            else if (member.sex == 'f') {
                femaleCount += 1;
            }
            else if (member.sex == 'other') {
                otherCount += 1;
            }
            else {
                console.log("error on teammember");
                console.log(member);
                errorCount += 1;
            }

        } );
        // Now we have the counts for the team members, let's categorize the team
        if (errorCount > 0) {
            listOfStartupsWithErrors.push(startup);
            TwoOrMoreMembersInStartupComposition["error"] += 1;
            
        }
        else if (maleCount > 0 && femaleCount == 0 && otherCount == 0 && errorCount == 0) {
            TwoOrMoreMembersInStartupComposition["male"] += 1;
        }
        else if (femaleCount > 0 && maleCount == 0 && otherCount == 0 && errorCount == 0) {
            TwoOrMoreMembersInStartupComposition["female"] += 1;
        }
        else if (otherCount > 0 && maleCount == 0 && femaleCount == 0 && errorCount == 0) {
            TwoOrMoreMembersInStartupComposition["other"] += 1;
        }
        else {
            TwoOrMoreMembersInStartupComposition["mixed"] += 1;
        }
        
    });

    console.log("Errors v1+v2");
    console.log(listOfStartupsWithErrors);

// Same for v2:

// For startups with two or more members, iterate over the team members and check if teams are pure male, female, other or mixed
activeV2StartupsWithTwoOrMoreMembers.forEach(startup => {
    let teamMembers = Object.values(startup.teammedlemmer).map(uid => teammembers && teammembers[uid]);
    let maleCount = 0;
    let femaleCount = 0;
    let otherCount = 0;
    let errorCount = 0;
    
    //First, check the idehaver
    
    if (idehavere[startup.idehaver].sex == 'm') {
        maleCount += 1;
    }
    else if (idehavere[startup.idehaver].sex == 'f') {
        femaleCount += 1;
    }
    else if (idehavere[startup.idehaver].sex == 'other') {
        otherCount += 1;
    }
    else {
        console.log("v2 error on idehaver");
        console.log(idehavere[startup.idehaver]);
        errorCount += 1;
    }
    teamMembers.forEach(member => {
        
        if (member.sex == 'm') {
            maleCount += 1;
            
        }
        else if (member.sex == 'f') {
            femaleCount += 1;
        }
        else if (member.sex == 'other') {
            otherCount += 1;
        }
        else {
            console.log("v2 error on teammember");
            console.log(member);
            
            errorCount += 1;
        }

    } );
    // Now we have the counts for the team members, let's categorize the team
    if (errorCount > 0) {
        v2TwoOrMoreMembersInStartupComposition["error"] += 1;
        v2ListOfStartupsWithErrors.push(startup);
        
    }
    else if (maleCount > 0 && femaleCount == 0 && otherCount == 0 && errorCount == 0) {
        v2TwoOrMoreMembersInStartupComposition["male"] += 1;
    }
    else if (femaleCount > 0 && maleCount == 0 && otherCount == 0 && errorCount == 0) {
        v2TwoOrMoreMembersInStartupComposition["female"] += 1;
    }
    else if (otherCount > 0 && maleCount == 0 && femaleCount == 0 && errorCount == 0) {
        v2TwoOrMoreMembersInStartupComposition["other"] += 1;
    }
    else {
        v2TwoOrMoreMembersInStartupComposition["mixed"] += 1;
    }
    
});
console.log("v2 Errors");
console.log(v2ListOfStartupsWithErrors);



    

    return (
        <div className="col-lg-12">
            <h1>DENNE SIDEN ER IKKE FERDIG</h1>
            <div className="row">

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        
                        <div className="container text-center">
                            <h1 className="display-4">
                                {onlyV2 ? v2OnlyOneMemberInStartup["male"] : OnlyOneMemberInStartup["male"]}
                            </h1>
                            <p className="lead">
                                aktive solo menn
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                            {onlyV2 ? v2OnlyOneMemberInStartup["female"] : OnlyOneMemberInStartup["female"]}
                            </h1>
                            <p className="lead">
                                aktive solo kvinner
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                            {onlyV2 ? v2OnlyOneMemberInStartup["other"] : OnlyOneMemberInStartup["other"]}
                            </h1>
                            <p className="lead">
                                aktive solo har ikke spesifisert
                            </p>
                        </div>
                    </div>
                </div>
            </div>












            <div className="row">

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        
                        <div className="container text-center">
                            <h1 className="display-4">
                                {onlyV2 ? v2TwoOrMoreMembersInStartupComposition["male"] : TwoOrMoreMembersInStartupComposition["male"]}
                            </h1>
                            <p className="lead">
                                Grupper med bare menn
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {onlyV2 ? v2TwoOrMoreMembersInStartupComposition["female"] : TwoOrMoreMembersInStartupComposition["female"]}
                            </h1>
                            <p className="lead">
                                Grupper med bare kvinner
                            </p>
                        </div>
                    </div>
                </div>


                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {onlyV2 ? v2TwoOrMoreMembersInStartupComposition["other"] : TwoOrMoreMembersInStartupComposition["other"]}
                            </h1>
                            <p className="lead">
                                Grupper med bare "andre"
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {onlyV2 ? v2TwoOrMoreMembersInStartupComposition["mixed"] : TwoOrMoreMembersInStartupComposition["mixed"]}
                            </h1>
                            <p className="lead">
                                Grupper med blanding
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {onlyV2 ? v2TwoOrMoreMembersInStartupComposition["error"] : TwoOrMoreMembersInStartupComposition["error"]}
                            </h1>
                            <p className="lead">
                                Grupper som mangler kjønnsdata på en eller flere medlemmer:
                                {/* List of startup names that are missing. When the list is printed it lloks like this:
                                 */}
                                 <ul style={{ fontSize: 'small' }}>
                                    {onlyV2 ? 
                                        v2ListOfStartupsWithErrors.map((startup, index) => (
                                            <li key={index}>{startup.ideNavn || "Unnamed Startup"}</li>
                                        )) 
                                        : 
                                        listOfStartupsWithErrors.map((startup, index) => (
                                            <li key={index}>{startup.ideNavn || "Unnamed Startup"}</li>
                                        ))
                                    }
                                </ul>
                                
                            </p>
                        </div>
                    </div>
                </div>



            </div>






            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setOnlyV2(!onlyV2)}
                />
                <label className="form-check-label mb-4 ml-4" htmlFor="flexSwitchCheckDefault">
                    Bare bruk data fra v2
                </label>
            </div>

        </div>
    );
}

export default withTranslation('common')(TeamCompositions);
