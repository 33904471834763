import React from 'react';

const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="pull-right">sparkntnu.no ALPHA 1.9.5 - Oct 24 13:30 </div>
    </div>
  );
};

export default FooterComponent;
