import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from './feedback';
import { feedbackMessages } from '../../constants';
import hostname from '../../config/hostname';

const requestStartupUpdate = () => ({
  type: 'REQUEST_STARTUP_UPDATE',
});

const startupUpdateSuccess = () => ({
  type: 'STARTUP_UPDATE_SUCCESS',
});

const startupUpdateError = (error) => ({
  type: 'STARTUP_UPDATE_ERROR',
  error,
});

function updateStartupInfo(
  startupInfo,
  startupId,
  idehaverInfo,
  forrigeVeileder,
  teamMembers,
  tags
) {
  return (dispatch) => {
    dispatch(requestStartupUpdate());

    let {
      ideNavn,
      ideBeskrivelse,
      organisasjonsNr,
      registreringsDato,
      bransje,
      fase,
      status,
      veileder,
      idehaver,
      teammedlemmer,
      nettside,
      logoUrl,
      meetingNotes,
      eit,
      stotte,
      stotteType,
      kvinneprosjektet,
      motivation,
      ambitions,
      sparkExpectations,
      lackingCompetance,
    } = startupInfo;

    if (stotte === undefined) {
      stotte = '';
    }
    if (stotteType === undefined) {
      stotteType = '';
    }
    if (meetingNotes === undefined) {
      meetingNotes = '';
    }

    const updates = {};

    let teamKeys = [];
    if (teamMembers) {
      teamKeys = Object.keys(teamMembers).map((key) => {
        if (!teamMembers[key].uid) {
          return teamMembers[key];
        }
        updates[`/teammembers/${teamMembers[key].uid}`] = teamMembers[key];
        return teamMembers[key].uid;
      });
    } else {
      teamKeys = [];
    }
    teamKeys = [...new Set(teamKeys)];

    const startupData = {
      ideNavn,
      ideBeskrivelse,
      organisasjonsNr,
      registreringsDato,
      bransje,
      fase,
      status,
      veileder,
      idehaver,
      teammedlemmer: teamKeys,
      nettside,
      logoUrl,
      tags,
      meetingNotes,
      stotte,
      stotteType,
    };

    if (hostname === 'basen' || hostname === 'localhost') {
      startupData.eit = eit || '';
      let firstSave = false;
      // if kvinnerprosjektet is "Spark* NTNU" make it boolean false, if "Boost Henne" make it boolean true
      if (kvinneprosjektet == 'Spark* NTNU') {
        kvinneprosjektet = false;
        firstSave = true;
      } else if (kvinneprosjektet == 'Boost Henne') {
        kvinneprosjektet = true;
        firstSave = true;
        
      }
      if (!firstSave) {
        startupData.kvinneprosjektet = kvinneprosjektet || '';
      }
      else {
        startupData.kvinneprosjektet = kvinneprosjektet;
      }
      
    }

    if (hostname === 'sparknord' || hostname === 'localhost') {
      startupData.motivation = motivation || '';
      startupData.ambitions = ambitions || '';
      startupData.sparkExpectations = sparkExpectations || '';
      startupData.lackingCompetance = lackingCompetance || '';
    }

    let {
      navn,
      sex,
      mobilnr,
      email,
      alternativeMail,
      studieSted,
      alder,
      kull,
      erfaring,
      familieVenner,
      erfarenGrunder,
      deltattKurs,
      arbeidserfaring,
      horteomspark,
      startupRole,
      shareContactInformation,
      competance,
    } = idehaverInfo;

    // New data not yet populated.
    if (!alder) {
      alder = '';
    }
    if (!kull) {
      kull = '';
    }
    if (erfaring === undefined) {
      erfaring = '';
    }
    if (familieVenner === undefined) {
      familieVenner = '';
    }
    if (erfarenGrunder === undefined) {
      erfarenGrunder = '';
    }
    if (deltattKurs === undefined) {
      deltattKurs = '';
    }
    if (arbeidserfaring === undefined) {
      arbeidserfaring = '';
    }
    if (startupRole === undefined) {
      startupRole = '';
    }
    if (horteomspark === undefined) {
      horteomspark = '';
    }
    if (competance === undefined) {
      competance = '';
    }

    const idehaverData = {
      navn,
      sex,
      mobilnr,
      email,
      alternativeMail,
      studieSted,
      alder,
      kull,
      erfaring,
      familieVenner,
      erfarenGrunder,
      deltattKurs,
      arbeidserfaring,
      horteomspark,
      startupRole,
      competance,
    };

    if (hostname === 'sparknord' || hostname === 'localhost') {
      idehaverData.shareContactInformation = shareContactInformation || '';
    }

    updates[`/startups/${startupId}`] = startupData;
    updates[`/idehavere/${idehaver}`] = idehaverData;
    updates[`/users/${veileder.value}/startups/${startupId}`] = startupId;
    if (forrigeVeileder.value !== veileder.value) {
      updates[`/users/${forrigeVeileder.value}/startups/${startupId}`] = null;
    }

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        feedback.success(feedbackMessages.startupEditSuccess);
        dispatch(startupUpdateSuccess());
      })
      .catch((err) => {
        feedback.error(err);
        dispatch(startupUpdateError(err.message));
      });
  };
}

export default updateStartupInfo;
