import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaTrash, FaCheck, FaUserPlus } from 'react-icons/fa';
import { ShowDate, generatePushID } from '../../redux/actions/utils';
import Select from 'react-select';
import { teamMemberStatus } from '../../constants/index';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import hostname from '../../config/hostname';

function EditTeamMember(props) {
  const { tags, t } = props;
  const { studieOptions, horteomsparkOptions, roleOptions } = tags.tagsList;
  const studyList = Object.keys(studieOptions).map(
    (studyKey) => studieOptions[studyKey]
  );
  const [teamMember, setTeamMember] = useState({
    aarskurs: '',
    epost: '',
    telefonnr: '',
    horteomspark: '',
    kompetanse: '',
    linkedin: '',
    navn: '',
    registreringsdato: '',
    status: '',
    studieretning: '',
    uid: '',
    sex: '',
  });
//Try fix for deletion of teammember
const id = props.id !== undefined ? parseInt(props.id) : 0;
  const [alert, setAlert] = useState(null);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    // Existing member
    if (props.member !== undefined) {
      const thatsMe = props.member;
      setTeamMember(thatsMe);
    } else {
      // New Member
      // Generate firebase-key (not pushed)
      const newKey = generatePushID();
      const now = new Date();
      const timeNow = `${now.toISOString().split('.')[0]}Z`;

      setTeamMember({
        ...teamMember,
        registreringsdato: timeNow,
        uid: newKey,
      });
      setNewUser(true);
    }
  }, []);

  function handleStatusChange(event) {
    setTeamMember({
      ...teamMember,
      status: event.label,
    });
  }

  function handleHorteomsparkChange(event) {
    setTeamMember({
      ...teamMember,
      horteomspark: event.label,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    props.handleMemberInTeam(teamMember, id, false);
    props.closeModal();
  }

  function hideAlert() {
    setAlert(null);
  }

  function deleteThisPost(e) {
    e.preventDefault();

    const getAlert = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText={t('admin.YesDeleteIt')}
        cancelBtnText={t('admin.Cancel')}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={t('admin.AreYouSure')}
        onCancel={() => hideAlert()}
        onConfirm={() => confirmDelete()}
      >
        {t('admin.Delete')} {teamMember.navn} ?
      </SweetAlert>
    );

    setAlert(getAlert());
  }

  function confirmDelete() {
    const confirmed = () => (
      <SweetAlert success title="Ok!" onConfirm={() => hideAlert()}>
        {t('startups.MemberHasBeenDeleted')}
      </SweetAlert>
    );

    setAlert(confirmed());

    // Deleting TeamMember from State, + updating
    props.handleMemberInTeam(teamMember, id, true);
    props.closeModal();
  }

  return (
    <div>
      <form>
        <div>
          <h2 className="font-color-orange p-lg">
            <FaUserPlus className="m-r-xl" /> {t('startups.Member')}:{' '}
            {teamMember.navn} TEST 2
          </h2>

          {hostname === 'localhost' || hostname === 'sparknord' ? (
            <div>
              <label className="col-sm-12 control-label">
                Kan vi dele kontaktinformasjon internt i Spark*, Start og
                Engage?
              </label>
              <div className="col-sm-12">
                <label className="col-sm-4 control-label" />
                <div className="col-sm-4 m-b pt-md-2">
                  <div className="col-sm-4">
                    <label>
                      <input
                        type="radio"
                        checked={
                          teamMember.shareContactInformation === true
                            ? true
                            : ''
                        }
                        onChange={() =>
                          setTeamMember({
                            ...teamMember,
                            shareContactInformation: true,
                          })
                        }
                      />
                      Ja
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <label>
                      <input
                        type="radio"
                        checked={
                          teamMember.shareContactInformation === false
                            ? true
                            : ''
                        }
                        onChange={() =>
                          setTeamMember({
                            ...teamMember,
                            shareContactInformation: false,
                          })
                        }
                      />
                      Nei
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <label className="col-sm-4 control-label">{t('startups.Name')}</label>
          <div className="input-group m-b col-sm-6">
            <input
              type="text"
              disabled={props.readOnly}
              className="form-control"
              value={teamMember.navn}
              required
              onChange={(e) =>
                setTeamMember({ ...teamMember, navn: e.target.value })
              }
            />
          </div>

          <label className="col-sm-4 control-label">
            {t('startups.Email')}
          </label>
          <div className="input-group m-b col-sm-6">
            <input
              type="text"
              disabled={props.readOnly}
              className="form-control"
              value={teamMember.epost}
              onChange={(e) =>
                setTeamMember({ ...teamMember, epost: e.target.value })
              }
            />
          </div>

          <label className="col-sm-4 control-label">
            {t('startups.PhoneNumber')}
          </label>
          <div className="input-group m-b col-sm-6">
            <input
              type="text"
              disabled={props.readOnly}
              className="form-control"
              value={teamMember.telefonnr}
              onChange={(e) =>
                setTeamMember({ ...teamMember, telefonnr: e.target.value })
              }
            />
          </div>

          <label className="col-sm-4 control-label">
            {t('startups.Study')}
          </label>
          <div className="form-group m-b col-sm-6">
            <Select
              styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
              value={{
                label: teamMember.studieretning,
                value: teamMember.studieretning,
              }}
              options={studyList}
              onChange={(e) =>
                setTeamMember({ ...teamMember, studieretning: e.label })
              }
            />
          </div>

          <label className="col-sm-4 control-label">Rolle i startup</label>
          <div className="form-group m-b col-sm-6">
            <Select
              styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
              value={{
                label: teamMember.startupRole || '',
                value: teamMember.startupRole || '',
              }}
              options={roleOptions}
              onChange={(e) =>
                setTeamMember({ ...teamMember, startupRole: e.label })
              }
            />
          </div>

          <label className="col-sm-4 control-label">
            {t('startups.ClassYear')}
          </label>
          <div className="input-group m-b col-sm-6">
            <input
              type="number"
              disabled={props.readOnly}
              className="form-control"
              value={teamMember.aarskurs}
              onChange={(e) =>
                setTeamMember({ ...teamMember, aarskurs: e.target.value })
              }
            />
          </div>

          <label className="col-sm-4 control-label">
            {t('startups.Gender')}
          </label>
          <div className="col-sm-8 m-b pt-md-2">
            <div className="col-sm-4">
              <label>
                <input
                  type="radio"
                  checked={teamMember.sex === 'f' ? true : ''}
                  onChange={() => setTeamMember({ ...teamMember, sex: 'f' })}
                />
                {t('startups.Woman')}
              </label>
            </div>
            <div className="col-sm-4">
              <label>
                <input
                  type="radio"
                  checked={teamMember.sex === 'm' ? true : ''}
                  onChange={() => setTeamMember({ ...teamMember, sex: 'm' })}
                />
                {t('startups.Man')}
              </label>
            </div>
            <div className="col-sm-4">
              <label>
                <input
                  type="radio"
                  checked={teamMember.sex === 'other' ? true : ''}
                  onChange={() =>
                    setTeamMember({ ...teamMember, sex: 'other' })
                  }
                />
                {t('startups.Other')}
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">
              {t('startups.Competance')}
            </label>
            <div className="input-group m-b col-sm-6">
              <textarea
                type="text"
                rows="6"
                disabled={props.readOnly}
                className="form-control"
                value={teamMember.kompetanse}
                onChange={(e) =>
                  setTeamMember({ ...teamMember, kompetanse: e.target.value })
                }
              />
            </div>
          </div>

          <div className="m-b-xl">
            <div className="form-group">
              <div className="col-sm-4">
                <label className="control-label">Hørte om Spark: </label>
                <br />
                <Select
                  name="horteomspark-input"
                  value={
                    {
                      label: teamMember.horteomspark,
                      value: teamMember.horteomspark,
                    } || ''
                  }
                  options={horteomsparkOptions}
                  onChange={handleHorteomsparkChange}
                />
              </div>
              <div className="col-sm-4">
                <label className="control-label">
                  {t('startups.RegisterDate')}:{' '}
                </label>
                <br />
                <ShowDate regDate={teamMember.registreringsdato} />
              </div>
              {/*               <div className="col-sm-4">
                <label className="control-label">Status: </label>
                <br />
                <Select
                  name="status-input"
                  value={{ label: teamMember.status, value: teamMember.status }}
                  options={teamMemberStatus}
                  onChange={handleStatusChange}
                />
              </div>
              Status not being used elsewhere in code.
              Could be used to archive old teammemmbers instead of deleting them.
              */}
            </div>
          </div>
          <p />
          <br />
          <br />
          <br />
          {props.readOnly ? (
            <button
              type="button"
              className="btn btn-default pull-right"
              onClick={props.closeModal}
            >
              {t('startups.Close')}
            </button>
          ) : (
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary pull-right "
                onClick={handleSubmit}
              >
                <FaCheck className="m-r-xs" /> {t('startups.Save')}
              </button>
              <button
                type="button"
                className="btn btn-default pull-right "
                onClick={props.closeModal}
              >
                {t('startups.Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                disabled={newUser}
                onClick={deleteThisPost}
              >
                <FaTrash className="m-r-xs" /> {t('startups.DeleteMember')}
              </button>
            </div>
          )}
        </div>
        {alert}
      </form>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    tags: state.tags,
  }))
)(EditTeamMember);
