import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { updateCurrentView } from '../redux/actions/nav';
import PageHeader from '../components/common/PageHeader';
import LoadingSquare from '../components/common/LoadingSquare';
import DashboardIconWidget from '../components/dashboard/DashboardIconWidget.tsx';
import StartupsThisMonth from '../components/dashboard/StartupsThisMonth';
import LackingInfoBox from '../components/dashboard/LackingInfoBox';
import alumniLyspære from '../assets/alumni-lyspære.png';
import aktivLyspære from '../assets/aktiv-lyspære.png';
import dødeLyspære from '../assets/døde-lyspære.png';
import dvaleLyspære from '../assets/dvale-lyspære.png';

function DashboardContainer(props) {
  const { dispatch, idehavere, startups, t, lackingInfo } = props;
  const [active, setActive] = useState(0);
  const [alumni, setAlumni] = useState(0);
  const [dead, setDead] = useState(0);
  const [hibernate, setHibernate] = useState(0);
  const [capacity, setCapacity] = useState(0);
   //variable to show or hide the changelog, except for the latest version
   const [showChangelog, setShowChangelog] = useState(false); //This gives minified react error 312 when set to true.. Fix: 
  

  useEffect(() => {
    setShowChangelog(false);
    dispatch(updateCurrentView('dashboard'));
    if (props.startups.startupList && props.users.userList) {
      const startups = Object.keys(props.startups.startupList).map(
        (key) => props.startups.startupList[key]
      );
      const users = Object.keys(props.users.userList).map(
        (key) => props.users.userList[key]
      );
      const states = {
        Aktiv: 0,
        Død: 0,
        Alumni: 0,
        Dvale: 0,
      };

      startups.forEach((s) => states[s.status.label]++);
      setDead(states['Død']);
      setActive(states.Aktiv);
      setAlumni(states.Alumni);
      setHibernate(states.Dvale);
      const numOr0 = (n) => (isNaN(n) ? 0 : n); // checking if not a number
      setCapacity(
        users.reduce((prev, cur) => numOr0(prev) + numOr0(cur.capacity), 0, 0)
      );
    }
  }, [props.users, props.startups]);
  if (
    !props.startups.startupList ||
    !props.users.userList ||
    lackingInfo.fetchingData
  ) {
    return (
      <div>
        <PageHeader view="Dashboard" subview={null} />
        <LoadingSquare />
      </div>
    );
  }

  const percent =
    capacity === 0 ? 100 : ` ${Math.floor((active / capacity) * 100)}%`;


    const sparkPuns = [
      "Tenk stort, Spark lyst!",
      "La deg inspirere av en gnist av Spark!",
      "Spark i gang dagen!",
      "Fang gnisten med Spark!",
      "Fang livets gnister med Spark!",
      "Bevar gnisten, behold Sparken!",
      "Med et Spark i steget!",
      "Sprudlende Spark hver dag!",
      "En Spark forandrer alt!",
      "Tenn gnisten med Spark!",
      "La Spark lyse opp veien!",
      "Lev livet lystig med litt Spark!",
      "Spark starter eventyret!",
      "Ny start, ny Spark!",
      "Følg din Spark til drømmene!",
      "Alle store ideer starter med en Spark!",
      "Sett verden i brann, Spark om Spark!",
      "La oss Spark-e til vi parker!",
      "En genistrek av en Spark!",
      "Du er en naturlig gnistfanger, en ekte Spark!",
      "Lys opp verden med din Spark!",
      "La din Spark skinne!",
      "Spark i gang en ny epoke!",
      "Spark i gang en ny tid!",
      "Spark i gang en ny æra!"
  ];

 
  const changelog = [
    "1.9.X: Preppe for lansering av nytt innsendingssytem som slår sammen Spark og Boost. Her vil nye startups automatisk havne i Basen, med rett statistikk ført. Nytt design på login påbegynt. Ny statistikk på teamsammensetning også. Sparky* har også fått sin første dag i det digitale univers. Sparky* er en robot som liker å skrive det første møtenotatet for nye startups. Mulig Sparky* kan hjelpe med mer senere. Kanskje Sparky* får AI etterhvert og. Kanskje Sparky* tar over verden? Hvem vet.",
    "1.8: Fikset registreringssiden",
    "1.7.5: Fikse redigere profil",
    "1.7.4: Store forbokstaver i oversikt over veiledere for de som ikke klarte å lage det selv",
    "1.7.3: Fikse at registreringssiden ikke dukket opp",
    "1.7.2: Nye farger for veiledere med over, lik eller under kapasitet, bugfixes"
  ];

  return (
    <div>
      <PageHeader view="Dashboard" subview={null} />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-xs-12">
            <LackingInfoBox lackingInfo={lackingInfo.lackingInfo} />



            <div className="ibox ibox-content" style={{ textAlign: 'center' }}>
              <h1 className="font-color-orange">
                <span className="font-bold">Basen*</span>V2 Alpha
                
              </h1>
              <h2>
                {sparkPuns[Math.floor(Math.random() * sparkPuns.length)]}
              </h2>
              <p>Nyeste changelog:</p>
              {/* Display first item always, and a button to show/hide the rest */}
              <div>
                {changelog[0]}
                

                {showChangelog ? (
                  <div>
                    {changelog.slice(1).map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                ) : (
                  <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowChangelog(true)}
                  >
                    Vis flere endringer
                  </button>
                  </div>
                )}

                </div>
            </div>
    



            <div className="ibox ibox-content" style={{ textAlign: 'center' }}>
              <h1 className="font-color-orange">
                <span className=" font-bold">Spark*</span> {t('dashboard.isOn')}
                {percent} {t('dashboard.capacity')}.
              </h1>
              <h2>
                {t('dashboard.WeCanGuide')} {capacity - active}{' '}
                {t('dashboard.more')}
              </h2>
              <div
                style={{ borderRadius: '0' }}
                className="progress progress-striped active m-b-sm"
              >
                <div style={{ width: percent }} className="progress-bar" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <DashboardIconWidget
              number={alumni}
              title="Alumni"
              iconImageUrl={alumniLyspære}
            />
          </div>

          <div className="col-sm-6 col-md-3">
            <DashboardIconWidget
              number={active}
              title={t('dashboard.Active')}
              iconImageUrl={aktivLyspære}
            />
          </div>

          <div className="col-sm-6 col-md-3">
            <DashboardIconWidget
              number={dead}
              title={t('dashboard.Dead')}
              iconImageUrl={dødeLyspære}
            />
          </div>
          <div className="col-sm-6 col-md-3">
            <DashboardIconWidget
              number={hibernate}
              title={t('dashboard.Inactive')}
              iconImageUrl={dvaleLyspære}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="ibox">
            <StartupsThisMonth
              startupsList={props.startups.startupList}
              idehaverList={idehavere.idehaverList}
            />
          </div>
        </div>{' '}
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    nav: state.nav,
    idehavere: state.idehavere,
    users: state.users,
    startups: state.startups,
    teammembers: state.teammembers,
    tags: state.tags,
    auth: state.auth,
    lackingInfo: state.lackingInfo,
  }))
)(DashboardContainer);
